import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";

import routes from "./config";

import { GameStateProvider } from "../game/game-manager/game-state";
import { GameUIProvider } from "../game/game-manager/game-ui";
import { NetworkManagerProvider, useNetworkManager } from "../game/game-manager/network-manager";
import { InferenceProvider } from "../game/game-manager/inference";
import { StatsProvider } from "../game/game-manager/stats";

import AuthService from "../services/auth.service";
import IUser from '../types/user.type';

import EventBus from "../common/EventBus";
import Main from "./main";

const RouterWrapper: React.FC = () => {
  
  // const networkState = useNetworkManager();
  // const status = networkState.networkState;
  const [ gameStatus, setGameStatus ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState<IUser>();
  const [ showAdminBoard, setShowAdminBoard ] = useState(false);
  // const [ isLobby, setIsLobby ] = useState(false);
  useEffect(() => {
    // if(status.status === "STARTED") setGameStatus(true);
    
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowAdminBoard((user.role == "ROLE_ADMIN"));
      EventBus.on("logout", logOut);

      return () => {
        EventBus.remove("logout", logOut);
      }
    }
  
  }, [])

  // function set_isLobby(islobby = false) {
  //   setIsLobby(islobby);
  //   console.log("set as true:", isLobby);
  // }
  
  function logOut() {
    AuthService.logout();
    setCurrentUser(undefined);
    setShowAdminBoard(false);
  }

  return (
    <BrowserRouter>
      <GameStateProvider>
        <GameUIProvider>
          <NetworkManagerProvider>
            <InferenceProvider>
              <StatsProvider>
                <Main />
              </StatsProvider>
            </InferenceProvider>
          </NetworkManagerProvider>
        </GameUIProvider>
      </GameStateProvider>
    </BrowserRouter>
  );
};

export default RouterWrapper;


