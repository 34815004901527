import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthService from "../../../services/auth.service";

import {
  Lobby,
  OptionsForm,
  Header,
  Body,
  OptionsContainer,
  Option,
  RightContainer,
  StyledLink,
} from "./styles";

//components
import CreateGame from "./create-game";
import JoinGame from "./join-game";

interface Props {}

const LobbyComponent: React.FC<Props> = () => {
  const location = useLocation();
  const [currentUserRole, setCurrentUserRole] = useState("");
  const [selectedOptionId, setSelectedOptionId] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    setCurrentUserRole(AuthService.getCurrentUser()?.role);
  }, []);


  return (
    <Lobby>
      <OptionsForm>
        <Body>
          <RightContainer>
            {(currentUserRole === "ROLE_ADMIN") ? (
            <CreateGame />) : (<JoinGame />)}
          </RightContainer>
        </Body>
      </OptionsForm>
    </Lobby>
  );
};

export default LobbyComponent;
