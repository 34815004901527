import React, { useEffect, useState } from "react";
import UserService from "../../services/user.service";
import styled from "styled-components";
import "./home.css";

function Home() {
    const [content, setContent] = useState();
    // useEffect(() => {
    //     UserService.getPublicContent().then(
    //         response => {
    //             setContent(response.data);
    //         },
    //         error => {
    //             setContent(
    //                 (error.response && error.response.data) ||
    //                 error.message ||
    //                 error.toString()
    //             );
    //         }
    //       );
    // }, [])
    return(
        
        <Page>
            <header className="jumbotron">
                <h1 className = "content contentColor">WELCOME TO BACKGAMMON</h1>
            </header>
        </Page>
    );
}
const Page = styled.div`
  background-image: url(/wood-texture.png);

  height: 100%;
  padding: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Home;
