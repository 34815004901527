let hostname = window.location.hostname;
let API_URL: string, SOCKET_URL: string;
if(hostname === 'localhost') {
    API_URL = 'http://' + hostname + ':3001/api/';
    SOCKET_URL = 'ws://' + hostname + ':3001';
}
else {
    API_URL = 'https://' + hostname + '/api/';
    SOCKET_URL = 'wss://' + hostname;
}

export {
    API_URL, 
    SOCKET_URL
}