import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import IUser from "../../../types/user.type";
import AuthService from "../../../services/auth.service";
import styled from "styled-components";

import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { getFilterFormValues } from "react-admin";
import { setConstantValue } from "typescript";

// import "./register.css";

const Styles = styled.div`
  background-image: url(/wood-texture.png);
  height: 100%;
  padding: 10px;

  display: flex;
  justify-content: center;
  // align-items: center;
`;

const Register: React.FC = () => {
  const history = useHistory();
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [showState, setShowState] = useState(true);
  const [countryValue, setCountryValue] = useState("United States of America");
  const initialValues: IUser = {
    username: "",
    email: "",
    password: "",
    country: "United States of America",
    state:"Nebraska",
    gender: "Male",
    birthday: "",
  };
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("This field is required!"),
  });
  const handleRegister = (formValue: IUser) => {
    const { username, email, password, state, gender, birthday } = formValue;
    console.log(state);
    const country = countryValue;
    let role = "user";
    if (username === "backgammonAdmin") {
      role = "admin";
      console.log(role);
    }

    AuthService.register(username!, email!, password!, country!, state!, gender!, birthday!, role).then(
      (response) => {
        setMessage(response.message);
        setSuccessful(true);
        history.push("/game");
        window.location.reload();
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        setSuccessful(false);
      }
    );

  };

  const selectCountry = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event.currentTarget.value);
    setCountryValue(event.currentTarget.value);
    if(event.currentTarget.value === "United States of America") {      
      setShowState(true);
    }
    else setShowState(false);
  }

  return (
    <Styles>
      <div className="col-md-12">
        <div className="card card-container">
          <img
            src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
            alt="profile-img"
            className="profile-img-card"
          />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleRegister}
          >
            <Form>
              {!successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="username"> Username </label>
                    <Field name="username" type="text" className="form-control" />
                    <ErrorMessage
                      name="username"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="email"> Email </label>
                    <Field name="email" type="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password"> Password </label>
                    <Field
                      name="password"
                      type="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  
                  <div className="form-group">
                    <label htmlFor="country"> Country </label>
                    <Field
                      name="country"
                      as="select"
                      className="form-control"
                      value={countryValue}
                      onChange={selectCountry}
                    >
                      <option value="United States of America">United States of America</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="China">China</option>
                      <option value="Russia">Russia</option>
                      <option value="France">France</option>
                      <option value="Germany">Germany</option>
                    </Field>
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  {showState?
                  <div className="form-group" >
                    <label htmlFor="state"> State </label>
                    <Field
                      name="state"
                      as="select"
                      className="form-control"
                    >
                      <option value="Nebraska">Nebraska</option>
                      <option value="Florida">Florida</option>
                      <option value="Washington">Washington</option>
                      <option value="Kansas">Kansas</option>
                    </Field>
                    <ErrorMessage
                      name="country"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div> :
                  null
                  }
                  <div className="form-group">
                    <label htmlFor="gender"> Gender </label>
                    <Field as="select" name="gender" className="form-control">
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="birthday"> Birthday </label>
                    <Field
                      name="birthday"
                      type="date"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="birthday"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-primary btn-block">Sign Up</button>
                  </div>
                </div>
              )}
              {message && (
                <div className="form-group">
                  <div
                    className={
                      successful ? "alert alert-success" : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </Styles>
  );
};
export default Register;

