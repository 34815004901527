import React, { ReactComponentElement, FC, Component } from "react";
import LobbyPage from "./lobby";
import PlaygroundPage from "./playground";
import Login from "../game/components/login";
import Register from "../game/components/register";
import Home from "./Home";
import Profile from "../game/components/profile";
import BoardUser from "./boardUser";
import BoardAdmin from "./boardAdmin";
import { RouteComponentProps } from "react-router-dom";
import AllRoomsComponent from "../game/components/allRooms";
import AllUsersComponent from "../game/components/allUsers";
interface RouterProps {
  history: string;
}
type Props = RouteComponentProps<RouterProps>;
interface RouteConfig {
  id: string;
  path: string;
  component: FC<{}> | FC<Props> | Component;
  isExact: boolean;
}

const routes = [
  {
    id: "dashboard",
    path: "/",
    component: Home,
    isExact: true,
  },
  {
    id: "login",
    path: "/login",
    component: Login,
    isExact: true,
  },
  {
    id: "register",
    path: "/register",
    component: Register,
    isExact: true,
  },
  {
    id: "profile",
    path: "/profile",
    component: Profile,
    isExact: true,
  },
  {
    id: "boardUser",
    path: "/boardUser",
    component: BoardUser,
    isExact: true,
  },
  {
    id: "allRooms",
    path: "/roomStatus",
    component: AllRoomsComponent,
    isExact: true,
  },
  {
    id: "allUsers",
    path: "/users",
    component: AllUsersComponent,
    isExact: true,
  },
  {
    id: "lobby",
    path: "/game",
    component: LobbyPage,
    isExact: true,
  },
  {
    id: "lobby",
    path: "/game/create",
    component: LobbyPage,
    isExact: true,
  },
  {
    id: "lobby",
    path: "/game/join",
    component: LobbyPage,
    isExact: true,
  },
  {
    id: "lobby",
    path: "/about",
    component: LobbyPage,
    isExact: true,
  },
  {
    id: "playground",
    path: "/game/:id",
    component: PlaygroundPage,
    isExact: true,
  },
];

export default routes;
