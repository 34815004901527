import { ErrorType } from "./types";

export const Actions = {
  ALL_USERS_FETCHED: "ALL_USERS_FETCHED",
  ALL_ROOMS_FETCHED: "ALL_ROOMS_FETCHED",
  ROOMS_FETCHED: "ROOMS_FETCHED",
  ROOM_CREATED: "ROOM_CREATED",
  ROOM_JOINED: "ROOM_JOINED",
  GAME_STARTED: "GAME_STARTED",
  SYNC_STATE: "SYNC_STATE",
  EXECUTE_MOVE: "EXECUTE_MOVE",
  EXECUTE_ROLL: "EXECUTE_ROLL",
  NO_AVAILABLE_MOVES: "NO_AVAILABLE_MOVES",
  ERROR_OCCURRED: "ERROR_OCCURRED",
  SYNC_NETWORK_STATUS: "SYNC_NETWORK_STATUS",
  REMATCH_INVITATION_SENT: "REMATCH_INVITATION_SENT",
  REMATCH_INVITATION_DECLINED: "REMATCH_INVITATION_DECLINED",
  PLAYER_OUT: "PLAYER_OUT",
  NO_PLAYER: "NO_PLAYER",
  LOG_IN: "LOG_IN",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  // Emitters
  GET_ALL_USERS: "GET_ALL_USERS",
  GET_ALL_ROOMS: "GET_ALL_ROOMS",
  GET_ROOMS: "GET_ROOMS",
  CREATE_ROOM: "CREATE_ROOM",
  JOIN_ROOM: "JOIN_ROOM",
  INIT_ROLL: "INIT_ROLL",
  ROLL: "ROLL",
  MOVE: "MOVE",
  UNDO_MOVE: "UNDO_MOVE",
  CONFIRM_MOVE: "CONFIRM_MOVE",
  INVITE_FOR_REMATCH: "INVITE_FOR_REMATCH",
  ACCEPT_REMATCH: "ACCEPT_REMATCH",
  DECLINE_REMATCH: "DECLINE_REMATCH",
  QUIT_FROM_GAME: "QUIT_FROM_GAME",
  DELETE_ROOM: "DELETE_ROOM"
};

export const JOIN_ROOM_ERRORS: ErrorType[] = [
  "PASSWORD_NOT_VALID",
  "PASSWORD_NOT_PRESENT",
];
