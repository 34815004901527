import React, { useEffect, useState } from "react";
import userService from "../../../services/user.service";
import { UserInfo } from "../../../types/user.type";
import EventBus from "../../../common/EventBus";
import styled from "styled-components";

//components
const Styles = styled.div`
  background-image: url(wood-t.jpg);
  height: 100%;
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    margin: auto;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: center;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
    tr:hover {background-color: #D6EEEE;}

  }
`;



function AllUsersComponent() {

  const [ Datas, setDatas ] = useState<UserInfo[]>([]);
  const [ error, setError ] = useState("");


  useEffect(() => {

    userService.getAllUsers().then(
        response => {
            console.log(response.data);
            setDatas(response.data);
        },
        error => {
                setError(

                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString()
                )
            
    
            if (error.response && error.response.status === 401) {
              EventBus.dispatch("logout");
            }
        }
    );
  }, []);
  const deleteItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    userService.deleteUser(event.currentTarget.value).then(
      response => {
        console.log(response.data);
        setDatas(response.data);
      },
      error => {
        setError(

            (error.response &&
                error.response.data &&
                error.response.data.message) ||
                error.message ||
                error.toString()
        )
    

          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
      }
    );
    console.log(event.currentTarget.value);
  }

  const editItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    const result = Datas.filter(data => data.email === event.currentTarget.value);
    console.log(result);
    //Todo: make modal for editing content
  }
  
  return (
    <Styles>
      <table>
        <thead>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Gender</th>
            <th>Birthday</th>
            <th>Win Times</th>
            <th>Loss Times</th>
            <th>Created Date</th>
            <th>Last Login Date</th>
            <th>Delete</th>
            {/* <th>Edit</th> */}
          </tr>
        </thead>
        <tbody>
          {Datas.map((user, i) => (
            <tr key = {i}>
              <td>{ ++i }</td>
              <td>{ user.username }</td>
              <td>{ user.email }</td>
              {(user.state)?
              <td>{ user.country }/{user.state}</td>:
              <td>{ user.country }</td>
              }
              <td>{ user.gender }</td>
              <td>{ user.birthday }</td>
              <td>{ user.win_time }</td>
              <td>{ user.loss_time }</td>
              <td>{ user.created_date }</td>
              <td>{ user.last_login_date }</td>
              <td>
                <button value={user.email} onClick={deleteItem}>
                  Delete
                </button>
              </td>
              {/* <td>
                <button value={user.email} onClick={editItem}>
                  Edit
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </Styles>
  );
};

export default AllUsersComponent;

