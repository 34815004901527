import axios from 'axios';
import authHeader from './auth-header';
import { API_URL } from '../config';

class UserService {
  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getUserBoard() {
    return axios.get(API_URL + 'user', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getAllUsers() {
    return axios.get(API_URL + 'users', { headers: authHeader() });
  }

  deleteUser(deleteItem: string) {
    let data = {
      deleteItem: deleteItem
    }
    return axios.post(API_URL + 'delete', data, { headers: authHeader() });
  }
}

export default new UserService();