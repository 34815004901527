import axios from "axios";

import { API_URL } from "../config";

class AuthService {
  login(username: string, password: string) {
    return axios
      .post(API_URL + "auth/signin", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
    }

  logout() {
    axios
    .post(API_URL + "auth/logout", {
      username: this.getCurrentUser()
    })
    .then(
      response => localStorage.removeItem("user")
      )
  }

  register(username: string, email: string, password: string, country: string, state: string, gender: string, birthday: string, role: string) {
    return axios.post(API_URL + "auth/signup", {
      username,
      email,
      password,
      country,
      state,
      gender,
      birthday, 
      role,
    })
    .then(response => {
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
  
      return response.data;
    });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

export default new AuthService();
