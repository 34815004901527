import IO from "../io";
import { Actions } from "../constants";
import { NetworkState } from "../types";
import { PositionTransition } from "../../../types";
import { RoomsService } from "./useRooms";

export type VoidEmitter = () => void;

export interface EmitterService {
  createRoom: (roomName: string) => void;
  deleteRoom: (roomId: string) => void;
  joinRoom: (roomId: string, username: string, accessToken: string) => void;
  pressInitRoll: VoidEmitter;
  pressRoll: VoidEmitter;
  getRooms: VoidEmitter;
  getAllRooms: VoidEmitter;
  getAllUsers: VoidEmitter;
  selectToMove: (positionTranslation: PositionTransition) => void;
  confirmMove: VoidEmitter;
  undo: VoidEmitter;
  sendRematchInvitation: VoidEmitter;
  acceptRematchInvitation: VoidEmitter;
  declineRematchInvitation: VoidEmitter;
  quitFromGame: (status: string, roomId: string, role: string, hostUsername: string, guestUsername: string) => void;
}

interface Params {
  networkState: NetworkState;
  updateNetworkState: (networkState: NetworkState) => void;
  roomsService: RoomsService;
}

const useEmitters = (params: Params): EmitterService => {
  const { roomsService } = params;

  const getAllUsers = () => {
    roomsService.setIsLoadingAll(true);
    IO.emit(Actions.GET_ALL_USERS, "");
  };

  const getAllRooms = () => {
    console.log("call getallrooms function");
    roomsService.setIsLoadingAll(true);
    IO.emit(Actions.GET_ALL_ROOMS, "");
  };

  const getRooms = () => {
    roomsService.setIsLoading(true);
    IO.emit(Actions.GET_ROOMS, "");
  };

  const createRoom = (roomName: string) => {
    IO.emit(Actions.CREATE_ROOM, {
      roomName,
    });
  };

  const deleteRoom = (roomId: string) => {
    console.log("deleteRoom");
    IO.emit(Actions.DELETE_ROOM, {
      roomId,
    });
  };

  const joinRoom = (roomId: string, username: string, accessToken: string) => {
    IO.emit(Actions.JOIN_ROOM, {
      roomId,
      username,
      accessToken
    });
  };

  const pressInitRoll = () => {
    IO.emit(Actions.INIT_ROLL, "");
  };

  const pressRoll = () => {
    IO.emit(Actions.ROLL, "");
  };

  const selectToMove = (positionTranslation: PositionTransition) => {

    IO.emit(Actions.MOVE, positionTranslation);
  };
  
  const confirmMove = () => {
    IO.emit(Actions.CONFIRM_MOVE, "");
  };
  
  const undo = () => {
    IO.emit(Actions.UNDO_MOVE, "");
  };

  // Rematch logic
  const sendRematchInvitation = () => {
    IO.emit(Actions.INVITE_FOR_REMATCH, "");
  };

  const acceptRematchInvitation = () => {
    IO.emit(Actions.ACCEPT_REMATCH, "");
  };

  const declineRematchInvitation = () => {
    IO.emit(Actions.DECLINE_REMATCH, "");
  };

  const quitFromGame = (status: string, roomId: string, role: string, hostUsername: string, guestUsername: string) => {
    console.log("===========2.IO.emit called==============");
    IO.emit(Actions.QUIT_FROM_GAME, {status, roomId, role, hostUsername, guestUsername});
  };

  return {
    createRoom,
    deleteRoom,
    joinRoom,
    pressInitRoll,
    pressRoll,
    getRooms,
    getAllRooms,
    getAllUsers,
    selectToMove,
    confirmMove,
    undo,
    sendRematchInvitation,
    acceptRematchInvitation,
    declineRematchInvitation,
    quitFromGame
  };
};

export default useEmitters;
