import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Switch, Route, Link, useHistory } from "react-router-dom";

import routes from "./config";

import { useNetworkManager } from "../game/game-manager/network-manager";

import AuthService from "../services/auth.service";
import IUser from '../types/user.type';

import EventBus from "../common/EventBus";

const Main: React.FC = () => {
  
  const networkManager = useNetworkManager();
  const networkState = networkManager.networkState;
  const [ gameStatus, setGameStatus ] = useState(false);
  const [ currentUser, setCurrentUser ] = useState<IUser>();
  const [ showAdminBoard, setShowAdminBoard ] = useState(false);
  const history = useHistory();
  // const [ isLobby, setIsLobby ] = useState(false);
  useEffect(() => {    
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowAdminBoard((user.role == "ROLE_ADMIN"));
      EventBus.on("logout", logOut);

      return () => {
        EventBus.remove("logout", logOut);
      }
    }
  
  }, [])

  useEffect(() => {
    if(networkState.status === "STARTED" || networkState.status === "WAITING_FOR_GUEST") setGameStatus(true);
    else setGameStatus(false);
  }, [networkState])

  function logOut() {
    AuthService.logout();
    setCurrentUser(undefined);
    setShowAdminBoard(false);
    history.push("/login");
  }

  return (
    <div className="d-flex flex-column h-100">
        {!gameStatus &&
        
        <nav className="navbar navbar-expand navbar-dark bg-dark">
            <Link to={"/"} className="navbar-brand">
                Backgammon
            </Link>
            <div className="navbar-nav mr-auto">
                <li className="nav-item">
                    <Link to={"/game"} className="nav-link">
                    Lobby
                    </Link>
                </li>

                {showAdminBoard && (
                    <li className="nav-item">
                    <Link to={"/roomStatus"} className="nav-link">
                        Room Status
                    </Link>
                    </li>

                )}
                {showAdminBoard && (

                    <li className="nav-item">
                    <Link to={"/users"} className="nav-link">
                        Users Info
                    </Link>
                    </li>
                )}

                {/* {currentUser && (
                <li className="nav-item">
                    <Link to={"/user"} className="nav-link">
                    User
                    </Link>
                </li>
                )} */}
            </div>

            {currentUser ? (
                <div className="navbar-nav ms-auto">
                {/* <li className="nav-item nav-link">
                    Number Of Login {numberOfLogin}
                </li> */}
                <li className="nav-item">
                    <a className="nav-link" onClick={logOut}>
                    LogOut
                    </a>
                </li>
                </div>
            ) : (
                <div className="navbar-nav ms-auto">
                <li className="nav-item">
                    <Link to={"/login"} className="nav-link">
                    Login
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to={"/register"} className="nav-link">
                    Sign Up
                    </Link>
                </li>
                </div>
            )}
        </nav>
        }

        <div className="flex-fill">
        <Switch>
            {routes.map((route) => (

            <Route
            key={route.id}
            exact={route.isExact}
            path={route.path}
            component={route.component}
            />
            ))}
        </Switch>
        </div>
    </div>
  );
};

export default Main;


