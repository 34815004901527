import { useEffect } from "react";
import { useNetworkManager } from "../game-manager/network-manager";
import styled from "styled-components";
// import { useTable, useSortBy } from "react-table";

// import InfiniteScroll from "react-infinite-scroll-component";
// import { TextDecoder } from "util";

//components


const Styles = styled.div`
  background-image: url(wood-t.jpg);
  height: 100%;
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;
    margin: auto;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      text-align: center;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
    tr:hover {background-color: #D6EEEE;}

  }
`;


function AllRoomsComponent() {
  const { emitterService, roomsService } = useNetworkManager();

  const {
    allRooms,
  } = roomsService;

  useEffect(() => {
    emitterService.getAllRooms();
  }, []);
  // let i = 0;

  const deleteItem = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event.currentTarget.value);
    emitterService.deleteRoom(event.currentTarget.value);
  }

  const countPlayers = (j: number): number => {
    if(!(allRooms[j-1].hostUsername)) return 0;
    else if(allRooms[j-1].hostUsername && !(allRooms[j-1].guestUsername)) return 1;
    else return 2;
  }
  
  return (
    <Styles>
      <table>
        <thead>
          <tr>
            <th>Room No</th>
            <th>Room Name</th>
            <th>Live Users</th>
            <th>First Player</th>
            <th>Second Player</th>
            <th>First Player Country</th>
            <th>Second Player Country</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>

          {allRooms.map((room, i) => (
            <tr key = {i}>
              <td>{ ++i }</td>
              <td>{ room.name }</td>
              <td>{ countPlayers(i) }</td>
              <td>{ room.hostUsername }</td>
              <td>{ room.guestUsername }</td>
              <td>{ room.hostCountry }</td>
              <td>{ room.guestCountry }</td>
              <td>
                <button value={room.id} onClick={deleteItem}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Styles>
  );
}

export default AllRoomsComponent;
